import schttp from 'public/src/services/schttp'
const { langPath } = gbCommonInfo
import { replaceHrefSpecChar } from '@shein/common-function'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'

const handleImageJump = function (data, vueComponent) {
  var ids = {
    real: [],
    itemPicking: []
  }
  var skus = []
  var checkType = function (type, value) {
    switch (type) {
      case 'real':
        ids.real.push(value)
        break
      case 'realOne':
        ids.real.push(value)
        break
      case 'realTwo':
        ids.real.push(value)
        break
      case 'sku':
        skus.push(value)
        break
      case 'itemPicking':
        ids.itemPicking.push(value)
        break
    }
  }
  data.forEach(function (val) {
    val.operations.forEach(function (operation) {
      var comp = operation.content
      if (comp.type === 'IMAGE_COMPONENT' || comp.type === 'LIST_FLOW_BANNER_COMPONENT' || comp.type === 'LIST_BANNER_COMPONENT' || comp.type === 'EMPTY_CART_COMPONENT' || comp.type === 'TOP_BANNER_COMPONENT' || comp.type === 'POLICY_BANNER_COMPONENT' || comp.type === 'ORDER_TRACK_BANNER_COMPONENT' || comp.type === 'RANK_BANNER_COMPONENT' || comp.type === 'COUNT_DOWN_COMPONENT') {
        comp.props.items && comp.props.items.forEach(function (item) {
          if (item.hrefTarget) {
            checkType(item.hrefType, item.hrefTarget)
          }
        })
        comp.hotZoneData && comp.hotZoneData.forEach(function (hotZone) {
          var props = hotZone.props
          if (props.hrefTarget) {
            checkType(props.hrefType, props.hrefTarget)
          }
        })
      }
      if (comp.type === 'IMAGE_CAROUSEL_COMPONENT') {
        comp.props.items && comp.props.items.forEach(function (item) {
          if (item.hrefTarget) {
            checkType(item.hrefType, item.hrefTarget)
          }
        })
      }
      if (comp.type === 'TAIL_ITEMS_LIST_COMPONENT') {
        comp.props.items && comp.props.items.forEach(function (item) {
          item.tailAdvertFlowItems && item.tailAdvertFlowItems.forEach(function (item0) {
            if (item0.hrefTarget) {
              checkType(item0.hrefType, item0.hrefTarget)
            }
          })
        })
      }
      if (comp.type === 'PICTURE_WORD_COMPONENT') {
        if (comp.props.style.type === 'ITEM_PICTURE_WORD_DOUBLE_ROWS') {
          comp.props.items[0].commonItems && comp.props.items[0].commonItems.forEach(function (item) {
            if (item.hrefTarget) {
              checkType(item.hrefType, item.hrefTarget)
            }
          })
        } else {
          comp.props.items && comp.props.items.forEach(function (item) {
            if (item.hrefTarget) {
              checkType(item.hrefType, item.hrefTarget)
            }
          })
        }
      }
    })
  })
  // 数组去重
  function unique (a) {
    var res = []

    for (var i = 0, len = a.length; i < len; i++) {
      var item = a[i];

      (res.indexOf(item) === -1) && res.push(item)
    }

    return res
  }

  for (var key in ids) {
    ids[key] = unique(ids[key])
  }
  skus = unique(skus)
  function getUrl (key, detail) {
    var type = key.split('_')[0]
    if (Object.prototype.toString.call(detail) === '[object Object]') {
      switch (type) {
        case 'real':
          return langPath + '/' + (detail.cat_url_name && detail.cat_url_name.replace(/%/g, '')) + '-c-' + detail.cat_id + '.html'
        case 'itemPicking':
          return replaceHrefSpecChar({ href: langPath + (detail.sc_url ? detail.sc_url : '/' + detail.select_type_name + '/' + detail.select_name + '-sc-' + detail.sc_url_id + '.html') })
      }
    } else {
      return 'javascript:;'
    }
  }
  var cateInfos = {}
  // 两个接口都返回结果后再一次性改变config_index.cateInfo的值，避免频繁watch导致无法及时更新视图
  return new Promise(function (resolve) {
    var products
    var shouldSetValue = false
    if (ids.real.length || ids.itemPicking.length) {
      schttp({
        url: '/api/ccc/cateInfoIndex/get',
        params: { ids: ids }
      }).then(data => {
        data && Object.keys(data).forEach(function (key) {
          // 选品数据在 itemPickIds 对象中
          if (key === 'itemPickIds') {
            data[key] && Object.keys(data[key]).forEach(function (pickKey) {
              cateInfos[pickKey] = getUrl(pickKey, data[key][pickKey])
            })
          }
          cateInfos[key] = getUrl(key, data[key])
          if (shouldSetValue && vueComponent) {
            vueComponent.$set(vueComponent, 'cateInfo', cateInfos)
          }
        })
        if (shouldSetValue) {
          resolve(cateInfos)
        }
        shouldSetValue = true
      })
    } else {
      shouldSetValue = true
    }

    if (skus.length) {
      schttp({
        method: 'POST',
        url: '/api/ccc/itemBySKU/get',
        data: { sku: skus }
      }).then(data => {
        if (data && data.code != 0) return
        products = (data.info && data.info.products) || []
        products.forEach(function (item) {
          var key = 'sku_' + item.goods_sn
          var url = getGoodsUrl(item.goods_url_name, item.goods_id, item.cat_id)
          cateInfos[key] = langPath + url
        })
        if (shouldSetValue && vueComponent) {
          vueComponent.$set(vueComponent, 'cateInfo', cateInfos)
        }
        if (shouldSetValue) {
          resolve(cateInfos)
        }
        shouldSetValue = true
      })
    } else {
      shouldSetValue = true
    }

    if (!(ids.real.length || ids.itemPicking.length || skus.length)) {
      resolve({})
    }
  })
}

export {
  handleImageJump,
}

